<template>
  <div>
    <el-card>
      <div slot='header' class='clearfix'>
        <span>{{ searchCondition.nickname || '-' }} 转组记录 </span>
      </div>
      <!--   table列表     -->
      <div class='default-table'>
        <el-table :data='dataList' highlight-current-row>
          <el-table-column type='index' label='序号'></el-table-column>
          <el-table-column prop='nickname' label='红人昵称' min-width='120'></el-table-column>
          <el-table-column prop='group_name' label='转入组' min-width='140'></el-table-column>
          <el-table-column prop='time_day' label='日期' min-width='100'></el-table-column>
          <el-table-column prop='remark' label='备注' min-width='200'></el-table-column>
          <el-table-column prop='created_at' label='操作时间' min-width='120'></el-table-column>
        </el-table>
        <div style='display: flex;flex-direction: row;justify-content: space-between;align-items: center'>
          <el-link icon='el-icon-refresh' @click='handleQuery'>刷新</el-link>
          <m-pagination @pagination='getList' :total.sync='total' :limit.sync='pagData.page_size'
                        :page.sync='pagData.current_page'></m-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>


export default {
  name: 'ArtistGroupsList',
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      orderSort: { time_day: 'desc', id: 'desc' },//默认排序规则
      total: 0,
      pagData: { page_size: 15, current_page: 1 },
      searchCondition: {}
    }
  },
  methods: {
    show(query) {
      this.searchCondition = query || {}
      this.handleQuery()
    },
    handleQuery() {
      this.pagData.current_page = 1
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },

    async getList() {
      this.loadingStatus = true
      this.dataList = []
      if (this.searchCondition.artist_id) {
        let pagingInfo = this.pagData
        let searchCondition = { artist_id: this.searchCondition.artist_id }
        // Object.assign(searchCondition, pagingInfo)
        Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
        let { list, pages } = await this.$api.signArtistGroupList(searchCondition)
        this.dataList = list || []
        this.pagData.page_size = pages.page_size
        this.pagData.current_page = pages.current_page
        this.total = pages.total
      }

      this.loadingStatus = false
    }
  }
}
</script>

<style scoped>

</style>
